import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import "./updateEffectiveDate.scss";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { ApiPatch } from "../../../../helper/helpers/api/apiData";
import { useDispatch, useSelector } from "react-redux";
import { getApiCount } from "../../../../redux/reducer/workflow";
import Loader from "../../../../components/loader";

export default function EditEffectiveDate(props) {
  const [errors, setErrors] = useState("");
  const [loader, setLoader] = useState(false);
  const { data, apiCount ,getwFlowData } = useSelector((state) => state.workflow);
  const [value, setValue] = useState(
    props?.effectiveDate ? props?.effectiveDate : ""
  );

  useEffect(() => {
    setValue(props?.effectiveDate);
  }, [props?.effectiveDate]);

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  // To update effective date
  const updateDate = async () => {
    setLoader(true);
    if (value) {
      let formData = new FormData();
      formData.append(
        "effective_date",
        moment(new Date(value)).format("YYYY-MM-DDTHH:mm:ss") + "Z"
      );

      await ApiPatch(
        `admin/update-passport-credentials?id=${data?._id}`,
        formData
      )
        .then((res) => {
          props.setEffectiveDate(res?.data?.data?.effective_date);
          toast.success("Effective date updated");
          setValue(null);
          setLoader(false);
          props.setModalOpen(!props.modalOpen);
        })
        .catch((err) => {
          toast.error("An error occurred.");
          console.log("err", err);
          setLoader(false);
        });
    }
  };
  return (
    <>
      <Toaster />
      <div className="update-date-workflow-modal-wrapper">
        <div className="workflow-modal-md">
          <div className="modal-right-content-alignment">
            <i
              className="fa-solid fa-xmark"
              onClick={() => {
                props.setModalOpen(!props.modalOpen);
              }}
            ></i>
          </div>
          <p>Effective date: </p>
          <div className="update-datepicker-style">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select from the calander"
                disablePast
                value={value}
                onChange={(newValue, e) => {
                  setValue(new Date(newValue));
                  setErrors({ ...errors, date: "" });
                }}
                inputFormat="DD.MM.YYYY"
                renderInput={(params) => (
                  <TextField {...params} onKeyDown={onKeyDown} />
                )}
              />
            </LocalizationProvider>
          </div>
          {loader ? (
              <div className="publish-change-button-alignment-save">
                <button>
                  <Loader/>
                </button>
              </div>
            ) : (
              <div className="publish-change-button-alignment-save">
              <button onClick={() => updateDate()}>Save</button>
            </div> 
          )}
          
        </div>
      </div>
    </>
  );
}
