import React, { useEffect, useState, useRef } from "react";
import DateIcon from "../../../assets/icons/date.svg";
import AttachmentIcon from "../../../assets/icons/attachment.svg";
import CopyrightIcon from "../../../assets/icons/copyright.svg";
import DeleteIcon from "../../../assets/icons/delete.svg";
import "./workflowAction.scss";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  ApiGet,
  ApiPatch,
  ApiPost,
  ApiPut,
} from "../../../helper/helpers/api/apiData";
import toast, { Toaster } from "react-hot-toast";
import {
  getApiCount,
  publishPayload,
  selectVariable,
  sendWorkFlowData,
  setSelectedId,
} from "../../../redux/reducer/workflow";
import RemoveModal from "../../../components/removeModal";
import { useHistory } from "react-router-dom";
import UpdateEffectiveDate from "../workflowAction/updateEffectiveDate";
import useOnClickOutside from "../../../helper/constant/outsideClick";
import Loader from "../../../components/loader";
import { Tooltip } from "@mui/material";

export default function WorkflowAction(props) {
  const { loader, setLoader } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [showRemoveModal, setShowRemoveModal] = useState({
    remove: false,
    attachments: false,
  });
  const {
    data,
    apiCount,
    getSelectedId,
    selectVarId,
    payloadData,
    getwFlowData,
  } = useSelector((state) => state.workflow);
  const [effectiveDate, setEffectiveDate] = useState(
    getwFlowData?.disease_data?.[0]?.effective_date
      ? getwFlowData?.disease_data?.[0]?.effective_date
      : ""
  );

  // To set effective date intially and after updating its value

  useEffect(() => {
    setEffectiveDate(getwFlowData?.disease_data?.[0]?.effective_date);
  }, [getwFlowData]);

  //Edit Effective Date popup
  const [modalOpen, setModalOpen] = useState("");
  const outsideRef = useRef(null);

  // To call api for get workflow component data
  useEffect(() => {
    getWorkflowData();
  }, [data, apiCount]);

  const getWorkflowData = async () => {
    if (!data?._id) {
      history.push("/home");
    } else {
      setLoader("true");
      await ApiGet(`admin/get-workflow-component?diseaseId=${data?._id}`)
        .then((res) => {
          dispatch(sendWorkFlowData(res?.data?.data));
          dispatch(getApiCount(0));
          setLoader("false");
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  // To add and delete variable card from workflow

  useEffect(() => {
    if (selectVarId?.action === "remove") {
      handleRemoveVariable();
    }
    if (selectVarId?.action === "addVariable") {
      handleAddVariable();
    }
    if (selectVarId?.action === "selectVariable") {
      handleSelectVaribale();
    }
    if (selectVarId?.action === "addTitle") {
      handleAddTitle();
    }
  }, [selectVarId, payloadData]);

  // To remove variable

  const handleRemoveVariable = async () => {
    if (selectVarId?.id && selectVarId?.action === "remove") {
      await ApiPatch(
        `admin/update-workflow-component?id=${selectVarId?.id}`,
        selectVarId?.type
      )
        .then(() => {
          dispatch(selectVariable(""));
          getWorkflowData();
          toast.success("Removed successfully");
        })
        .catch((err) => {
          dispatch(selectVariable(""));
          console.log(err);
        });
    }
  };

  // To add variable

  const handleAddVariable = async () => {
    if (payloadData.resId && payloadData?.perform === "publish") {
      let Type = payloadData.type;
      await ApiPatch(`admin/update-workflow-component?id=${selectVarId?.id}`, {
        [Type]: payloadData.resId,
      })
        .then(() => {
          dispatch(selectVariable(""));
          getWorkflowData();
          toast.success("Added successfully");
          dispatch(publishPayload({}));
        })
        .catch((err) => {
          dispatch(selectVariable(""));
          dispatch(publishPayload({}));
          console.log(err);
        });
    }
  };

  // To select variable

  const handleSelectVaribale = async () => {
    if (selectVarId?.id && selectVarId?.action === "selectVariable") {
      await ApiPatch(
        `admin/update-workflow-component?id=${selectVarId?.id}&select=true`,
        selectVarId?.type
      )
        .then(() => {
          dispatch(selectVariable(""));
          getWorkflowData();
          toast.success("Added successfully");
          dispatch(publishPayload({}));
        })
        .catch((err) => {
          dispatch(selectVariable(""));
          dispatch(publishPayload({}));
          console.log(err);
        });
    }
  };

  // To add row title

  const handleAddTitle = async () => {
    if (selectVarId?.text?.id && selectVarId?.action === "addTitle") {
      await ApiPatch(
        `admin/update-workflow-component?id=${selectVarId?.text?.id}`,
        {
          title: selectVarId.text.value,
        }
      )
        .then(() => {
          dispatch(selectVariable(""));
          getWorkflowData();
          toast.success("Title edited successfully");
        })
        .catch((err) => {
          dispatch(selectVariable(""));
          console.log(err);
        });
    }
  };

  // to copy row multiple row can be copied

  const handleCopyRow = async () => {
    if (getSelectedId) {
      const body = {
        id: getSelectedId,
      };
      await ApiPost(`admin/copy-workflow-component`, body)
        .then((res) => {
          toast.success("Row created");
          getWorkflowData();
          dispatch(setSelectedId(""));
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  // to add new row in workflow component

  const handleAddRow = async (e) => {
    if (data?._id) {
      var body = {
        title: "",
        occasion: "",
        user_profile: "",
        identity: "",
        prrof_of_immunity: "",
        detection: "",
        prevention: "",
        control: "",
        diseaseId: data?._id,
        status: "draft",
      };

      await ApiPost(`admin/add-workflow-component`, body)
        .then((res) => {
          toast.success("New row created");
          getWorkflowData();
          dispatch(selectVariable(""));
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  // to delete workflow component

  const deleteWorkflow = async () => {
    const body = {
      id: getSelectedId,
    };
    {
      getSelectedId &&
        (await ApiPut(`admin/delete-workflow-component`, body)
          .then(() => {
            dispatch(setSelectedId(""));
            getWorkflowData();
            handleClose();
            toast.success("Deleted successfully");
          })
          .catch((err) => {
            console.log("err", err);
          }));
    }
  };

  // To close delete modal
  const handleClose = () => {
    setShowRemoveModal(false);
  };

  //Edit Effective Date
  const onCancel = () => {
    setModalOpen("");
  };

  useOnClickOutside(outsideRef, onCancel);

  return (
    <>
      <Toaster />
      <div className="workflow-action-sub-header-alignment">
        <div className="left-side-content">
          <div className="date-content-alignment">
            <img src={DateIcon} alt="DateIcon" />
            <p>Effective date: </p>
            <Tooltip title="Edit date">
              <span onClick={() => setModalOpen(true)}>
                {moment(effectiveDate).format("DD.MM.YYYY")}
              </span>
            </Tooltip>
          </div>
          <div className="attachment-all-content-alignment">
            <img src={AttachmentIcon} alt="AttachmentIcon" />
            <p>Attachments: </p>
            <Tooltip title="Edit attachment">
              <span onClick={() => history.push("/editattachment")}>
                {getwFlowData?.disease_data?.[0]?.attachments?.length} files
              </span>
            </Tooltip>
          </div>
        </div>
        <div className="right-side-content">
          <>
            <Tooltip title="Copy row">
              <img
                src={CopyrightIcon}
                alt="CopyrightIcon"
                onClick={(e) => {
                  getSelectedId?.length > 0
                    ? handleCopyRow(e)
                    : toast.error("Please select row");
                }}
              />
            </Tooltip>
          </>
          <>
            <Tooltip title="Remove row">
              <img
                src={DeleteIcon}
                alt="DeleteIcon"
                onClick={() =>
                  getSelectedId?.length > 0
                    ? setShowRemoveModal({ attachments: false, remove: true })
                    : toast.error("Please select row")
                }
              />
            </Tooltip>
          </>
          <span
            onClick={(e) => {
              handleAddRow(e);
            }}
          >
            + New row
          </span>
        </div>
      </div>
      {showRemoveModal.remove === true && (
        <RemoveModal
          onRemove={deleteWorkflow}
          onClose={handleClose}
          title=" Are you sure you want to delete all  information in this variable?"
          description="This action can not be undone"
          cancel="Cancel"
          approve="Yes, delete it!"
        />
      )}
      {modalOpen === true && (
        <UpdateEffectiveDate
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setEffectiveDate={setEffectiveDate}
          effectiveDate={effectiveDate}
        />
      )}
    </>
  );
}
