import React, { useRef, useState } from "react";
import "./header.scss";
import GalleryIcon from '../../assets/icons/gallery.svg';
import PofileIcon from '../../assets/icons/user-profile-icon.svg';
import twoFA from '../../assets/icons/2FA.svg';
import Chevron from '../../assets/icons/chevron.svg';
import ExitIcon from '../../assets/icons/exit.svg';
import { NavLink, useHistory } from "react-router-dom";
import useOnClickOutside from "../../helper/constant/outsideClick";
export default function Header() {
  const user = JSON.parse(localStorage.getItem('user'));
  const [ profiledropdown , setProfiledropdown ] = useState(false);
  const outsideRef = useRef(null);
  const history = useHistory()

  const handleLogout = () =>{
    localStorage.removeItem('qrcode');
    localStorage.removeItem('x-access-token');
    localStorage.removeItem('user');
    history.push("/login");
    window.location.reload();
  }
  const onCancel = () => {
    setProfiledropdown(false);
  };
  useOnClickOutside(outsideRef, onCancel);


  return (
      <div className="header">
        <div className="container-fluid">
          <div className="header-content-alignment">
            <NavLink to="/home">
            <div className="left-side-content">
              <img src={GalleryIcon} alt="GalleryIcon"/>
              <p>{user?.firstName}  {user?.lastName}</p>
            </div>
            </NavLink>
            
            <div className="right-side-content">
              <button onClick={()=> setProfiledropdown(!profiledropdown)}>
                <div>{user?.firstName?.charAt(0)}{user?.lastName?.charAt(0)}</div>
                <span> <img src={Chevron} alt="Chevron"/></span>
              </button>
              <div className={profiledropdown ? "header-dropdown-design profile-show" : "header-dropdown-design profile-hidden" }>
                <div className="header-dropdown-design-style" ref={outsideRef}>
                  <div className="all-icon-text-alignment">
                    <div className="icon-text">
                      <div>
                        <img src={PofileIcon} alt="PofileIcon"/>
                      </div>
                      <div>
                        <span>Your Profile</span>
                      </div>
                    </div>
                    <div className="icon-text">
                      <div>
                        <img src={twoFA} alt="twoFA"/>
                      </div>
                      <div>
                        <span>Disable Two-Factor Authentication </span>
                      </div>
                    </div>
                    <div className="icon-text" onClick={handleLogout}>
                      <div>
                        <img src={ExitIcon} alt="ExitIcon"/>
                      </div>
                      <div>
                        <span>Logout</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
