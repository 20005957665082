import React, { useEffect } from "react";
import './verifyAccount.scss';
import LetterIcon from '../../assets/icons/letter.png';
import Authentication from "../../assets/icons/authentication.svg";
import ProfileIcon from "../../helper/iconColor/porofileIcon";
import { toast, Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { ApiPostNoAuth } from "../../helper/helpers/api/apiData";
import { useSelector } from "react-redux";


export default function VerifyAccount() {
const { loginData } = useSelector((state) => state.workflow);
const pathname = window.location.pathname ;
const history = useHistory();
console.log("loginData",loginData)
  
    useEffect(()=>{
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const token = params.get('token');
      const email = params.get('email');
      handleVerifyEmail(token,email);
    },[pathname])
  
    const handleVerifyEmail = async (token ,email) => {
      if (token && email) {
        await ApiPostNoAuth(`admin/verify_admin_user?email=${email}&token=${token}`)
          .then((res) => {
            toast.success("Email verified successfully")
            localStorage.setItem("x-access-token",JSON.stringify(res?.data?.message?.token))
            localStorage.setItem("user",JSON.stringify(res?.data?.message));
            history.push("/register")
          })
          .catch((err) => {
            toast.error(err);
          });
      }
    };
    
    const resendEmail = async () =>{
        const body = {
            email : loginData.email,
            organization : loginData.organizationname,
            password : loginData.password,
          }
          loginData &&  await ApiPostNoAuth(`admin/add-admin`,loginData)
        .then((res) => {
          toast.success("Verification link to send to email successfully")
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  
    return(
        <>
           <Toaster />
            <div className="verify-account-all-contnet-alignment">
                <div className="verify-account">
                <div className="text-style">
                        <h4>Verify your account</h4>
                    </div>
                    <div className="icon-text-all-content-alignment">
                        <div className="icon-text">
                            <div>
                            <ProfileIcon color="#3CB44B"/>
                            </div>
                            <div>
                                <span>CREATE ACCOUNT</span>
                            </div>
                        </div>
                        <div className="icon-text">
                            <div>
                            <ProfileIcon color="#76767E"/>
                            </div>
                            <div>
                                <span>CREATE PROFILE</span>
                            </div>
                        </div>
                        <div className="icon-text">
                            <div>
                            <img src={Authentication} alt="Authentication"/>
                            </div>
                            <div>
                                <span>ENABLE 2FA</span>
                            </div>
                        </div>
                    </div>
                    <div className="only-text-style">
                        <span>Account activation link has been sent to the email address you provided.</span>
                    </div>
                    <div className="icon-center-alignment">
                        <img src={LetterIcon} alt="LetterIcon"/>
                    </div>
                    <div className="dont-email-text">
                        <p>Didn’t get an email? <span onClick={resendEmail}>Resend</span></p>
                    </div>

                </div>
            </div>
        </>
    )
}