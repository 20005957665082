import React from "react";
import './removeModal.scss';
import AlertIcon from '../../assets/icons/alert-icon.svg';
export default function RemoveModal(props){
    return(
        <>
            <div className="remove-modal-wrapper">
                <div className="remove-modal">
                    <div className="icon-center-alignment">
                        <img src={AlertIcon} alt="AlertIcon"/>
                    </div>
                    <h1>
                    {props?.title}
                    </h1>
                    <p>{props?.description}</p>
                    <div className="button-grid">
                        <div className="button-grid-items">
                            <button onClick={props.onClose}>{props?.cancel}</button>
                        </div>
                        <div className="button-grid-items">
                            <button onClick={props.onRemove}>{props?.approve}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}