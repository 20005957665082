import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginData : {},
  data: 0,
  apiCount: 0,
  resCount: 0,
  getwFlowData: "",
  tableData: "",
  addData: "",
  editData: "",
  getSelectedId: "",
  selectVarId: "",
  getTableData: [],
  payloadData: {},
  getRowVariable: {},
  getEditInput: "",
  getFiles:[],
  getRemoveFileId:[],
  getDeleteId:[],
};

export const Workflow = createSlice({
  name: "Workflow",
  initialState,
  reducers: {
    setLoginData : (state, action) => {
      console.log(state, action,"state, action")
      state.loginData = action.payload;
    },
    menuData: (state, action) => {
      state.data = action.payload;
    },
    sendWorkFlowData: (state, action) => {
      state.getwFlowData = action.payload;
    },
    sendTableData: (state, action) => {
      state.tableData = action.payload;
    },
    rowWorkFlowData: (state, action) => {
      state.addData = action.payload;
    },
    rowEditData: (state, action) => {
      state.editData = action.payload;
    },
    getSubWorkflow: (state, action) => {
      state.subData = action.payload;
    },
    setSelectedId: (state, action) => {
      state.getSelectedId = action.payload;
    },
    selectVariable: (state, action) => {
      state.selectVarId = action.payload;
    },
    setSubWTableData: (state, action) => {
      state.getTableData = action.payload;
    },
    publishPayload: (state, action) => {
      state.payloadData = action.payload;
    },
    getApiCount: (state, action) => {
      state.apiCount = action.payload;
    },
    resApiCount: (state, action) => {
      state.resCount = action.payload;
    },
    sendRowVariable: (state, action) => {
      state.getRowVariable = action.payload;
    },
    editVariableInput: (state, action) => {
      state.getEditInput = action.payload;
    },
    setUploadFile: (state, action) => {
      state.getFiles = action.payload;
    },
    setRemoveFileId: (state, action) => {
      state.getRemoveFileId = action.payload;
    },
    sendDeleteId: (state, action) => {
      state.getDeleteId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoginData,
  menuData,
  rowWorkFlowData,
  rowEditData,
  getSubWorkflow,
  sendWorkFlowData,
  sendTableData,
  setSelectedId,
  selectVariable,
  setSubWTableData,
  publishPayload,
  getApiCount,
  resApiCount,
  sendRowVariable,
  editVariableInput,
  setUploadFile,
  setRemoveFileId,
  sendDeleteId,
} = Workflow.actions;

export default Workflow.reducer;
