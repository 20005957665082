import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { ApiPost } from "../../helper/helpers/api/apiData";
import './authenticationCode.scss';
export default function AuthenticationCode() {
    const [code ,setCode] = useState("")
    const history = useHistory();
    const userId = JSON.parse(localStorage.getItem('user'));

    const handleSubmitCode = async () => {
        const body = {
          code : code ,
          userId : userId?.id
        }
        await ApiPost(`admin/2fa-authentication`,body)
        .then((res) => {
          localStorage.setItem("user",JSON.stringify(res?.data?.message));
          toast.success("Your account was successfully created! Two factor authentication  was enabled for your account.")
          history.push("/credentials")
          window.location.reload()
        })
        .catch((err) => {
          toast.error(err);
          console.log("err",err)
    
        });
      };
    return(
        <><Toaster />
            <div className="authentication-code-all-contnt-alignment">
                <div className="authentication-code-box">
                    <div className="text-style">
                        <h4>Authentication code</h4>
                        <p>
                            Open the two-factor authenticator 
                            app on your mobile phone to get your authentication code
                        </p>
                    </div>
                    <div className="input-design">
                    <input placeholder="XXXX" name="code" value={code} onChange={(e)=>setCode(e.target.value)} />
                        <span
                        style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                        }}
                        ></span>
                    </div>
                    <div className="checkbox-text-content-alignment">
                        <div>
                            <input type="checkbox"/>
                        </div>
                        <div>
                            <span>Remember me on this device for 30 days</span>
                        </div>
                    </div>
                    <div className="login-button-center-alignment">
                        <button onClick={handleSubmitCode}>Verify</button>
                    </div>
                    <div className="dont-have-account">
                        <p>Having problems? <a>Contact us</a></p>
                    </div>
                </div>
            </div>
        </>
    )
}