import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { ApiPost } from "../../helper/helpers/api/apiData";
import ProfileIcon from "../../helper/iconColor/porofileIcon";
import './authenticationSection.scss';

export default function AuthenticationSection() {
    const [code ,setCode] = useState("")
    const history = useHistory();
    const userId = JSON.parse(localStorage.getItem('user'));
    const qrcode = localStorage.getItem("qrcode")
    
    const handleSubmitCode = async () => {
        if(!code?.trim()){
            toast.error("Please enter verification code");
        }
        else{
      const body = {
        code : code ,
        userId : userId?.id
      }
      await ApiPost(`admin/2fa-authentication`,body)
      .then((res) => {
        localStorage.setItem("user",JSON.stringify(res?.data?.message));
        toast.success("Your account was successfully created! Two factor authentication  was enabled for your account.")
        history.push("/credentials");
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err);
        console.log("err",err)
      });
    }
    };

    const handleSkip = () =>{
        history.push("/credentials")
        window.location.reload();
    }
  
    return(
        <><Toaster />
            <div className="authentication-section-all-contnet-alignment">
                <div className="authentication-section-box">
                <div className="text-heading-style">
                        <h5>Enable Two-Factor Authentication</h5>
                    </div>
                    <div className="icon-text-all-content-alignment">
                        <div className="icon-text">
                            <div>
                            <ProfileIcon color="#3CB44B" filled="filled"/>
                            </div>
                            <div>
                                <span> CREATE ACCOUNT</span>
                            </div>
                        </div>
                        <div className="icon-text">
                            <div>
                            <ProfileIcon color="#3CB44B" filled="filled"/>
                            </div>
                            <div>
                                <span>CREATE PROFILE</span>
                            </div>
                        </div>
                        <div className="icon-text">
                            <div>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9204 12.0865C10.884 13.3221 9.58232 14.1202 8 14.4875C6.41771 14.1202 5.11632 13.3221 4.0804 12.0866L4.08028 12.0864C3.01871 10.8212 2.5 9.43565 2.5 7.915V4.36038L8 2.52705L13.5 4.36038V7.915C13.5 9.43571 12.9815 10.8213 11.9204 12.0865ZM11.9204 12.0865L12.3035 12.4078L11.9204 12.0865Z" stroke="#3CB44B"/>
                            <path d="M6.47682 8.30135C6.25901 8.11316 5.94119 8.1513 5.76694 8.38653C5.59269 8.62177 5.62801 8.96502 5.84582 9.1532L7.47717 10.5627L10.574 6.91403C10.7625 6.69197 10.7486 6.34693 10.543 6.14337C10.3374 5.93981 10.0179 5.95482 9.82942 6.17688L7.37072 9.07368L6.47682 8.30135Z" fill="#3CB44B"/>
                            </svg>
                            </div>
                            <div>
                                <span>ENABLE 2FA</span>
                            </div>
                        </div>
                    </div>
                    <ol>
                        <li>
                        Use phone authenticator app to scan the QR code and the authentication code.  Learn more
                        </li>
                    </ol>
                    <div className="image-center-alignment">
                     <img src={qrcode} alt="qrcode"/>
                    </div>
                    <ol>
                        <li>Verify the code you got in the app</li>
                    </ol>
                    <div className="input-style-design">
                    <input placeholder="XXXX" name="code" value={code} onChange={(e)=>setCode(e.target.value)} />
                        <span
                        style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                        }}
                        ></span>
                    </div>
                    <div className="right-text-style">
                        <span onClick={handleSkip}>Skip for now</span>
                    </div>
                    <div className="button-design">
                        <button onClick={handleSubmitCode}>Done</button>
                    </div>
                    <div className="center-text-style">
                        <span>By signing up you accept <a>terms and privacy policy</a> </span>
                    </div>
                </div>
            </div>
        </>
    )
}