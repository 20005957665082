import React, { useState } from "react";
import "../workflowModal.scss";
import LinkIcon from "../../../../assets/icons/link-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { ApiPatch, ApiPost } from "../../../../helper/helpers/api/apiData";
import {
  menuData,
  publishPayload,
  rowWorkFlowData,
  selectVariable,
  sendTableData,
  setSubWTableData,
  getApiCount,
  setUploadFile,
  setRemoveFileId,
  sendDeleteId,
} from "../../../../redux/reducer/workflow";
import { toast, Toaster } from "react-hot-toast";
import SubTableHeader from "../subTableHeader";
import { IconMap } from "../../../../helper/constant";
import SubworkflowTableData from "./subworkflowTableData";
import Loader from "../../../../components/loader";
import EditAttachment from "../../../../routes/credentials/editAttachment";

export default function SubWorkflowTable(props) {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { apiCount, tableData, getTableData, selectVarId, getFiles , getRemoveFileId , getDeleteId} =
    useSelector((state) => state.workflow);
  const [title, setTitle] = useState(
    selectVarId?.titleType?.title ? selectVarId?.titleType?.title : ""
  );
  const [rowComment, setRowComment] = useState(
    selectVarId?.titleType?.comment ? selectVarId?.titleType?.comment : ""
  );
  const [rowKey, setRowKey] = useState(null);
  const [attachmentOpen, setAttachmentOpen] = useState(false);

  // To set input value as per their name and value

  const handleOnChangeData = (i, name, value) => {
    let getTableDataCopy = [...getTableData];
    let item = getTableDataCopy[i];
    let newItem = {
      ...item,
      [name]: value,
    };
    getTableDataCopy[i] = newItem;

    dispatch(setSubWTableData(getTableDataCopy));
  };

  
  // to post workflow sub component table data

  const handlePubish = async () => {
    setLoader(true);
    if (selectVarId?.action === "editVarible") {
      if (!title?.trim()) {
        toast.error("Please enter title");
        setLoader(false);
      } else {
        let formData = new FormData();
        formData.append("type", tableData?.type);
        formData.append("title", title);
        formData.append("data",JSON.stringify(getTableData));
        formData.append("comment", rowComment?.trim() ? rowComment.trim() : "");
        {
          getFiles?.length > 0 &&
            Array.from(getFiles).forEach((file) => {
              formData.append("attachments", file);
            });
        }
        {
          getDeleteId?.length > 0 &&
            Array.from(getDeleteId).forEach((delId) => {
              formData.append("deleteId", delId);
            });
        }

        await ApiPatch(
          `admin/update-workflow-sub-component?id=${selectVarId?.titleType?._id}`,
          formData
        )
          .then((res) => {
            toast.success("Edited successfully");
            props.setModalOpen(!props.modalOpen);
            dispatch(setSubWTableData([]));
            dispatch(setRemoveFileId([]));
            dispatch(sendDeleteId([]));
            setTitle("");
            dispatch(publishPayload({}));
            dispatch(selectVariable());
            setLoader(false);
            dispatch(getApiCount(apiCount + 1));
            dispatch(setUploadFile([]));
          })
          .catch((err) => {
            console.log("err", err);
            setLoader(false);
          });
      }
    } else {
      if (!title?.trim()) {
        toast.error("Please enter title");
        setLoader(false);
      } else {
        let formData = new FormData();
        formData.append("type", tableData?.type);
        formData.append("title", title);
        formData.append("data",JSON.stringify(getTableData));
        formData.append("comment", rowComment?.trim() ? rowComment.trim() : "");
        {
          getFiles?.length > 0 &&
            Array.from(getFiles).forEach((file) => {
              formData.append("attachments", file);
            });
        }

        await ApiPost(`admin/add-workflow-sub-component`, formData)
          .then((res) => {
            props.setModalOpen(!props.modalOpen);
            dispatch(setSubWTableData([]));
            dispatch(setUploadFile([]));
            dispatch(setRemoveFileId([]));
            dispatch(sendDeleteId([]));
            setTitle("");
            dispatch(
              publishPayload({
                resId: res.data.data._id,
                type: tableData?.type,
                perform: "publish",
              })
            );
            setLoader(false);
          })
          .catch((err) => {
            setLoader(false);
            console.log("err", err);
          });
      }
    }
  };

  const handleAttachDocument = () => {
    setAttachmentOpen(true);
  };

  return (
    <>
      <div className="workflow-modal-wrapper">
        <Toaster />
        <div className="workflow-modal-md">
          <div className="modal-right-content-alignment">
            <i
              className="fa-solid fa-xmark"
              onClick={() => {
                props.setModalOpen(!props.modalOpen);
                dispatch(rowWorkFlowData(""));
                dispatch(sendTableData(""));
                dispatch(setSubWTableData([]));
                dispatch(setUploadFile([]));
                dispatch(setRemoveFileId([]));
                dispatch(sendDeleteId([]));
              }}
            ></i>
          </div>
          <div className="workflow-header-content-alignment">
            <div className="left-content headerTextAlignment">
              <img src={IconMap[tableData?.type]} alt="VaccineIcon" />
              <input
                type="text"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="right-content"></div>
          </div>
          <div className="workflow-body-content-alignment">
            <SubTableHeader />
            <div className="role-wise-table-data-alignmnet">
              <table>
                <tr>
                  <th align="left">
                    <span>{tableData?.title?.[0]} </span>
                  </th>
                  <th align="left">
                    <span>{tableData?.title?.[1]}</span>
                  </th>
                  <th align="left">
                    <span>{tableData?.title?.[2]}</span>
                  </th>
                  <th align="left">
                    <span>{tableData?.title?.[3]}</span>
                  </th>
                  <th align="left">
                    <span>{tableData?.title?.[4]}</span>
                  </th>
                  {tableData?.title?.[5] && (
                    <th align="left">
                      <span>{tableData?.title?.[5]}</span>
                    </th>
                  )}
                  {tableData?.title?.[6] && (
                    <th align="left">
                      <span>{tableData?.title?.[6]}</span>
                    </th>
                  )}
                  {tableData?.title?.[7] && (
                    <th align="left">
                      <span>{tableData?.title?.[7]}</span>
                    </th>
                  )}
                  {tableData?.title?.[8] && (
                    <th align="left">
                      <span>{tableData?.title?.[8]}</span>
                    </th>
                  )}
                </tr>
                {getTableData?.length > 0 &&
                  getTableData?.map((res, index) => (
                    <SubworkflowTableData
                      res={res}
                      i={index}
                      key={index}
                      rowKey={rowKey}
                      setRowKey={setRowKey}
                      handleOnChangeData={handleOnChangeData}
                    />
                  ))}
              </table>
            </div>
            <div className="disease-text">
              <span>Comments</span>
            </div>
            <div className="covid-test-text">
              <textarea
                placeholder="Enter comments here"
                id={rowComment}
                name={rowComment}
                value={rowComment}
                onChange={(e) => setRowComment(e.target.value)}
              />
            </div>
          </div>
          <div className="publish-change-button-alignment">
            <div
              onClick={() => {
                handleAttachDocument();
              }}
            >
              <img src={LinkIcon} alt="LinkIcon" />
              {selectVarId?.titleType?.attachments?.length + getFiles?.length > 0 ?
               <span>
                Attach Documents : {(getRemoveFileId?.length > 0 ? getRemoveFileId?.length :  selectVarId?.titleType?.attachments?.length )+  getFiles?.length}
              </span>
              :
              <span>
                Attach Documents
              </span>
              }
            </div>
            {loader ? (
              <button>
                <Loader />
              </button>
            ) : (
              <button onClick={() => handlePubish()}>Save</button>
            )}
          </div>
        </div>
      </div>

      {attachmentOpen === true && (
        <div className="workflow-modal-wrapper">
          <div className="edit-attachment-right-side-modal-alignment">
            <EditAttachment
              attachmentOpen={attachmentOpen}
              setAttachmentOpen={setAttachmentOpen}
            />
          </div>
        </div>
      )}
    </>
  );
}
