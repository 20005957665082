import React from "react";
import { Toaster } from "react-hot-toast";
import "../subInputModal/subInputModal.scss";
import RightIcon from "../../../../../assets/icons/right-arrow-md.svg";
import { useSelector } from "react-redux";

export default function IdentityInputModal(props) {
  const { rowData, onClose, handleOnChangeData, i, type } = props;
  const { tableData } = useSelector((state) => state.workflow);
   
  // to set input data value
  const handleInput = (e) => {
    const { name, value } = e.target;
    handleOnChangeData(i, name, value);
  };

  return (
    <>
      <Toaster />
      <div className="international-modal-wrapper-xl">
        <div className="inernational-md">
          <div className="inernational-md-modal-alignment">
            <div className="inernational-md-header">
              <div className="left-alignment">
              <div onClick={onClose} ><img src={RightIcon} alt="RightIcon" /></div> 
                <input
                  type="text"
                  placeholder={tableData?.title?.[0]}
                  id="userGroup"
                  name="userGroup"
                  value={rowData?.userGroup}
                  onChange={(e) => handleInput(e)}
                />
              </div>
            </div>
            <div className="inernational-body-all-content-alignment">
              <div className="comming-form-box">{tableData?.title?.[1]}</div>
              <div className="country-input">
                <input
                  type="text"
                  placeholder={tableData?.title?.[1]}
                  name="ageLimits"
                  id="ageLimits"
                  value={rowData?.ageLimits}
                  onChange={(e) => handleInput(e)}
                />
              </div>
              <div className="travelling-box">{tableData?.title?.[2]} </div>
              <div className="country-input">
                <input
                  type="text"
                  placeholder={tableData?.title?.[2]}
                  name="gender"
                  id="gender"
                  value={rowData?.gender}
                  onChange={(e) => handleInput(e)}
                />
              </div>
              <div className="travelling-box"> {tableData?.title?.[3]} </div>
              <div className="country-input">
                <input
                  type="text"
                  placeholder={tableData?.title?.[3]}
                  name="physiologicalConditions"
                  id="physiologicalConditions"
                  value={rowData?.physiologicalConditions}
                  onChange={(e) => handleInput(e)}
                />
              </div>
              <div className="travelling-box"> {tableData?.title?.[4]} </div>
              <div className="country-input">
                <input
                  type="text"
                  placeholder={tableData?.title?.[4]}
                  name="healthConditions"
                  id="healthConditions"
                  value={rowData?.healthConditions}
                  onChange={(e) => handleInput(e)}
                />
              </div>
              {/* <div className="travelling-box"> {tableData?.title?.[5]} </div>
              <div className="country-input">
                <input
                  type="text"
                  placeholder={tableData?.title?.[5]}
                  name="comments"
                  id="comments"
                  value={rowData?.comments}
                  onChange={(e) => handleInput(e)}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
