import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiGet } from "../../helper/helpers/api/apiData";

const initialState = {
  dataGet: 0,
  data1: {},
  MasterData: {},
};

export const handlePubishdata = createAsyncThunk(
  "learnerjourneys/user",

  async (user) => {
    // const response = await axiosInstance.post("user", user, {
    //   headers: authHeader(),
    // });

    const response = await ApiGet("admin/get-passport-credentials");
    return response;
  }
);

export const Credentials = createSlice({
  name: "Credentials",
  initialState,
  reducers: {
    getData: (state, action) => {
      state.dataGet = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(handlePubishdata.pending, (state) => {
        // state.saveStatus = "loading";
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(handlePubishdata.fulfilled, (state, action) => {
        // state.saveStatus = "succeeded";
        state.MasterData = action.payload;
      })
      .addCase(handlePubishdata.rejected, (state) => {
        // state.saveStatus = "failed";
      });
  },
});

// Action creators are generated for each case reducer function
export const { getData } = Credentials.actions;

export default Credentials.reducer;
