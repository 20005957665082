import React, { useState } from "react";
import Loader from "../../components/loader";
import "./credworkflow.scss";
import WorkflowAction from "./workflowAction";
import WorkflowCard from "./workflowCard";
import WorkflowHeader from "./workflowHeader";
export default function Credworkflow() {
  const [loader, setLoader] = useState("true");

  return (
    <>
      <WorkflowHeader />
      <WorkflowAction loader={loader} setLoader={setLoader} />
      {/* {loader === "true" ? <Loader /> : */}
       <WorkflowCard />
       {/* } */}
    </>
  );
}
