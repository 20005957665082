import React from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import AuthenticationCode from "./authenticationCode";
import AuthenticationSection from "./authenticationSection";
import ChangePassword from "./changePassword";
import CreateProfile from "./createProfile";
import ForgotPassword from "./forgotPassword";
import Login from "./login";
import SignupPage from "./signupPage";
import VerifyAccount from "./verifyAccount";

export default function AuthRoutes() {
  return (
    <>
      <BrowserRouter>
      <Switch> 
        <RouteWrapper
          exact={true}
          path="/"
          component={Login}
          layout={LoginLayout}
        />
         <RouteWrapper
          exact={true}
          path="/signup"
          component={SignupPage}
          layout={LoginLayout}
        />
        <RouteWrapper
          exact={true}
          path="/login"
          component={Login}
          layout={LoginLayout}
        />
        <RouteWrapper
          exact={true}
          path="/forgotpassword"
          component={ForgotPassword}
          layout={LoginLayout}
        />
        <RouteWrapper
          exact={true}
          path="/change-password/:email"
          component={ChangePassword}
          layout={LoginLayout}
        />
        <RouteWrapper
          exact={true}
          path="/verifyEmail"
          component={VerifyAccount}
          layout={LoginLayout}
        />
        <RouteWrapper
          exact={true}
          path="/2fa"
          component={AuthenticationSection}
          layout={LoginLayout}
        />
        <RouteWrapper
          exact={true}
          path="/register"
          component={CreateProfile}
          layout={LoginLayout}
        />
        <RouteWrapper
          exact={true}
          path="/authentication-code"
          component={AuthenticationCode}
          layout={LoginLayout}
        />
        </Switch>
      </BrowserRouter>
    </>
  );
}
const LoginLayout = ({ children, match }) => <> <div style={{backgroundColor : "#F7F7F7"}}>{children}</div></>;

function RouteWrapper({ component: Component, layout: Layout, auth, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
