import React, { useEffect, useState } from "react";
import "./forgotPassword.scss";
import KeyIcon from "../../assets/icons/key.png";
import { useHistory } from "react-router-dom";
import { ApiPostNoAuth } from "../../helper/helpers/api/apiData";
import { toast, Toaster } from "react-hot-toast";

export default function ForgotPassword() {
  const [forgotEmail, setForgotEmail] = useState({});
  const [errors, setErrors] = useState("");
  const regexEmail =
    /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;
  const history = useHistory();
  const pathname = window.location.pathname;

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get("token");
    const email = params.get("email");
    handleVerifyEmail(token, email);
  }, [pathname]);

  const handleVerifyEmail = async (token, email) => {
    if (token && email) {
      await ApiPostNoAuth(
        `admin/verify_admin_user?email=${email}&token=${token}`
      )
        .then((res) => {
          toast.success("Email verified successfully");
          history.push(`/change-password/${email}`);
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const handleChange = (e) => {
    setForgotEmail((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (forgotEmail && !forgotEmail?.email?.trim()) {
      formIsValid = false;
      errors["email"] = "*Please enter email!";
    } else if (forgotEmail && !forgotEmail?.email?.match(regexEmail)) {
      formIsValid = false;
      errors["email"] = "*Please enter valid email!";
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleForgotPassword = async () => {
    if (validateForm()) {
      const body = {
        email: forgotEmail.email,
      };
      await ApiPostNoAuth(`admin/forgotPassword`, body)
        .then((res) => {
          toast.success("Reset password link sent to your email successfully ");
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };
  return (
    <>
      <Toaster />
      <div className="forgot-password-all-content-alignment">
        <div className="forgot-password-box">
          <div className="text-style">
            <h4>Forgot your password?</h4>
            <p>
              No worries we'll send you reset instructions to your email address
            </p>
          </div>
          <div className="all-input-contnet-alignment">
            <div className="main-input-content">
              <div className="label-content-alignment">
                <label>Find your account</label>
                <span>*</span>
              </div>
              <div className="input-design">
                <input
                  type="text"
                  placeholder="Enter your email"
                  name="email"
                  value={forgotEmail?.email}
                  onChange={(e) => handleChange(e)}
                />
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}
                >
                  {errors["email"]}
                </span>
              </div>
            </div>
          </div>
          <div className="image-center-alignment">
            <img src={KeyIcon} alt="KeyIcon" />
          </div>
          <div className="login-button-center-alignment">
            <button onClick={handleForgotPassword}>Reset password</button>
          </div>
          <div className="dont-have-account">
            <p>
              Didn’t get an email? <a onClick={handleForgotPassword}>Resend</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
