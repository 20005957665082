import React, { useEffect, useRef, useState } from "react";
import "./sidebar.scss";
import UserManagement from "../../assets/icons/user management.svg";
import ListIcon from "../../assets/icons/list.svg";
import AnalyticsIcon from "../../assets/icons/Analytics.svg";
import SettingsIcon from "../../assets/icons/settings.svg";
import HelpIcon from "../../assets/icons/help.svg";
import SelfPass from "../../assets/images/logo.svg";
import { NavLink, useHistory } from "react-router-dom";
import openImage from "../../assets/icons/sidebaropen.png";
import { useDispatch, useSelector } from "react-redux";
import { menuData } from "../../redux/reducer/workflow";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
} from "../../helper/helpers/api/apiData";
import toast from "react-hot-toast";
import { getData } from "../../redux/reducer/credentials";
import RemoveModal from "../removeModal";
import useDebounce from "../../helper/constant/usedeBounceHook";
import SubMenu from "./submenu";

export default function Sidebar({ setSidebarClose, sidebarClose }) {
  const pathName = window.location.pathname;
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.workflow);
  const history = useHistory();
  const wrapperRef = useRef(null);
  const [openRemoveModal, setOpenRemoveModal] = useState({
    open: false,
    id: "",
    loader: false,
  });

  // TO dispatch sidebar dicease submenu data

  const handleClick = async (datas) => {
    dispatch(menuData(datas));
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  // To close sidebar in outside click of sidebar for responsive design

  const handleClickOutside = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      window.innerWidth <= 768
    ) {
      setSidebarClose(!sidebarClose);
    }
  };

  useEffect(() => {
    getSubMenu();
  }, []);

  // To get previosly added passport credential for submenu data

  const getSubMenu = async () => {
    await ApiGet(`admin/get-passport-credentials`)
      .then((res) => {
        dispatch(getData(res?.data?.data));
        setOpenRemoveModal({ loader: false });
      })
      .catch((err) => {
        setOpenRemoveModal({ loader: false });
        console.log("err", err);
      });
  };

  // To copy whole passport credential set

  const handleCopyCredential = async (id) => {
    if (id) {
      await ApiPost(`admin/copy-passport-credentials?id=${id}`)
        .then((res) => {
          getSubMenu();
          toast.success("Passport credential copied");
          handleClick(res?.data?.data);
        })
        .catch((err) => {
          setOpenRemoveModal({ loader: false });
          toast.error("An error occurred.");
          console.log("err", err);
        });
    }
  };
  // To delete whole passport credential set

  const handleDeleteCredential = async () => {
    if (openRemoveModal?.id) {
      await ApiDelete(
        `admin/delete-passport-credentials?id=${openRemoveModal?.id}`
      )
        .then((res) => {
          setOpenRemoveModal({ open: false, id: "" });
          getSubMenu();
          toast.success("Passport credential removed");
          history.push("/credentials");
        })
        .catch((err) => {
          toast.error("An error occurred.");
          console.log("err", err);
          setOpenRemoveModal({ open: false, id: "" });
        });
    }
  };

  return (
    <>
      <div>
        <div
          ref={wrapperRef}
          className={
            sidebarClose === true ? "sidebar closesiderbar" : " sidebar"
          }
        >
          <div className="sidebar-all-menu-content-alignment">
            <>
              <NavLink to="/credentials">
                <div
                  className={
                    pathName === "/credentials" || pathName === "/workflow"
                      ? "sidebar-menu-active sidebar-menu"
                      : "sidebar-menu"
                  }
                >
                  <div className="sidebar-icon">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.5 1.8H2.25C2.00147 1.8 1.8 2.00147 1.8 2.25V7.5C1.8 7.74853 2.00147 7.95 2.25 7.95H7.5C7.74853 7.95 7.95 7.74853 7.95 7.5V2.25C7.95 2.00147 7.74853 1.8 7.5 1.8ZM2.25 0.5C1.2835 0.5 0.5 1.2835 0.5 2.25V7.5C0.5 8.4665 1.2835 9.25 2.25 9.25H7.5C8.4665 9.25 9.25 8.4665 9.25 7.5V2.25C9.25 1.2835 8.4665 0.5 7.5 0.5H2.25ZM6.01248 3.73747H3.73747V6.01247H6.01248V3.73747ZM7.5 14.05H2.25C2.00147 14.05 1.8 14.2514 1.8 14.5V19.75C1.8 19.9985 2.00147 20.2 2.25 20.2H7.5C7.74853 20.2 7.95 19.9985 7.95 19.75V14.5C7.95 14.2514 7.74853 14.05 7.5 14.05ZM2.25 12.75C1.2835 12.75 0.5 13.5335 0.5 14.5V19.75C0.5 20.7165 1.2835 21.5 2.25 21.5H7.5C8.4665 21.5 9.25 20.7165 9.25 19.75V14.5C9.25 13.5335 8.4665 12.75 7.5 12.75H2.25ZM6.01248 15.9875H3.73747V18.2625H6.01248V15.9875ZM15.6667 12.75H12.75V15.6667H15.6667V12.75ZM21.5 12.75H18.5833V15.6667H21.5V12.75ZM15.6667 15.6668H18.5835V18.5833H21.5V21.5H18.5833V18.5835H15.6667V15.6668ZM12.75 18.5834H15.6667V21.5001H12.75V18.5834ZM19.75 1.8H14.5C14.2514 1.8 14.05 2.00147 14.05 2.25V7.5C14.05 7.74853 14.2514 7.95 14.5 7.95H19.75C19.9985 7.95 20.2 7.74853 20.2 7.5V2.25C20.2 2.00147 19.9985 1.8 19.75 1.8ZM14.5 0.5C13.5335 0.5 12.75 1.2835 12.75 2.25V7.5C12.75 8.4665 13.5335 9.25 14.5 9.25H19.75C20.7165 9.25 21.5 8.4665 21.5 7.5V2.25C21.5 1.2835 20.7165 0.5 19.75 0.5H14.5ZM18.2625 3.73747H15.9875V6.01247H18.2625V3.73747Z"
                        fill={
                          pathName === "/credentials" ||
                          pathName === "/workflow"
                            ? "#375BD1"
                            : "#76767E"
                        }
                      />
                    </svg>
                  </div>
                  <div className="sidebar-text">
                    <span>Passport Credentials</span>
                  </div>
                </div>
              </NavLink>
            </>

            {(pathName === "/credentials" ||
              pathName === "/workflow" ||
              pathName === "/editattachment") && (
              <SubMenu
                data={data}
                pathName={pathName}
                handleCopyCredential={handleCopyCredential}
                handleClick={handleClick}
                openRemoveModal={openRemoveModal}
                setOpenRemoveModal={setOpenRemoveModal}
              />
            )}

            <NavLink to="/home">
              <div className="sidebar-menu">
                <div className="sidebar-icon">
                  <img src={UserManagement} alt="UserManagement" />
                </div>
                <div className="sidebar-text">
                  <span>User Management </span>
                </div>
              </div>
            </NavLink>
            <NavLink to="/home">
              <div className="sidebar-menu">
                <div className="sidebar-icon">
                  <img src={ListIcon} alt="ListIcon" />
                </div>
                <div className="sidebar-text">
                  <span>Transaction Ledger</span>
                </div>
              </div>
            </NavLink>
            <NavLink to="/home">
              <div className="sidebar-menu">
                <div className="sidebar-icon">
                  <img src={AnalyticsIcon} alt="AnalyticsIcon" />
                </div>
                <div className="sidebar-text">
                  <span>Analytics</span>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="last-sidebar-menu">
            <NavLink to="/home">
              <div className="sidebar-menu">
                <div className="sidebar-icon">
                  <img src={SettingsIcon} alt="SettingsIcon" />
                </div>
                <div className="sidebar-text">
                  <span>Settings</span>
                </div>
              </div>
            </NavLink>
            <NavLink to="/home">
              <div className="sidebar-menu">
                <div className="sidebar-icon">
                  <img src={HelpIcon} alt="HelpIcon" />
                </div>
                <div className="sidebar-text">
                  <span>Support</span>
                </div>
              </div>
            </NavLink>

            <div className="sidebar-logo">
              <img src={SelfPass} alt="SelfPass" />
            </div>
          </div>

          <div
            className="baricon"
            onClick={() => setSidebarClose(!sidebarClose)}
          >
            <img src={openImage} />
          </div>
        </div>
      </div>
      {openRemoveModal?.open && (
        <RemoveModal
          onRemove={() => handleDeleteCredential()}
          onClose={() => setOpenRemoveModal({ open: false, id: "" })}
          title=" Are you sure you want to delete the passport credential set ?"
          description="This action can not be undone"
          cancel="Cancel"
          approve="Yes, delete it!"
        />
      )}
    </>
  );
}
